<template>
  <div id="app">

    <div v-if="!maintenanceMode" class="mb-24" >
      <b-navbar fixed="top" toggleable="sm" type="dark" variant="dark" right >
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

        <b-navbar-brand><router-link to="/" class="text-white text-decoration-none" >Cloudenly Partners</router-link></b-navbar-brand>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right v-if="$route.meta.protected">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em class="mr-2">Signed in as </em>    <strong class="text-white"> {{ $accountUser.fname }} {{ $accountUser.lname }}</strong>
            </template>
            <b-dropdown-item @click="this.$logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item  v-else >
            <router-link to="apply" v-if="$route.name==='Login'" class="text-white">Apply</router-link>
          <router-link to="/" v-else="$route.name==='Signup'" class="text-white">Login</router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      </b-navbar>
    </div>
    <div v-if="!maintenanceMode" class="container-fluid">
      <router-view/>
    </div>

  <div v-if="maintenanceMode" class="w-full min-h-screen flex items-center justify-center darkGray">
        <div class="md:w-2/3 w-full px-4 text-white flex flex-col">
            <div class="w-full text-7xl font-bold">
                <h1 class="w-full md:w-3/3"> This portal is currently under maintenance</h1>
            </div>
            <div class="flex mt-8 flex-col md:flex-row md:justify-between">
                <p class="w-full md:w-3/3 text-gray-400">
              We are working on some internal changes. Please check back later.</p>

            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      maintenanceMode: true,
    };
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.darkGray{
  background-color: #01143e;
}
</style>
