import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Admin.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/apply',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue'),
  },
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/application/completed',
    name: 'SignCompleted',
    component: () => import(/* webpackChunkName: "about" */ '../views/SignCompleted.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      protected: true,
      auth_role : 'mgt'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
  },
  {
    path: '/partner/agreement',
    name: 'Agreement',
    meta: {
      protected: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Agreement.vue'),
  },
  {
    path: '/partner/pre',
    name: 'PreSignForm',
    meta: {
      protected: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PreSignForm'),
  },
  {
    path: '/partner/agreement/signed',
    name: 'SignComplete',
    meta: {
      protected: true
    },
    component: () => import('../views/SignCompleted.vue'),
  },
  {
    path: '/apply/completed',
    name: 'ApplyCompleted',
    component: () => import('../views/ApplyCompleted.vue'),
  },
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let accountUser   = (JSON.parse(localStorage.getItem("accountUser")));

  // store.commit("UPDATE_ACCOUNT_LOCALSTORAGE")

  if (to.meta.protected) {
    if (accountUser !== null) {
      next();
    } else {
      next("/")
    }
  } else {
    if (accountUser === null) {
      next();
    } else {
      next({name:'Admin'})
    }
  }
})
export default router;
