<template>
  <div class="mt-20">
    <div class="d-flex justify-content-center">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div  v-if="userCounts" class="row justify-content-around">
              <div class="col-md-3 col-sm-12">
                <h5>
                  <span class="badge badge-primary text-xl"
                    >Total Partners : {{ userCounts.total_partners  }}</span
                  >
                </h5>
                <h5>
                  <span class="badge badge-primary text-xl">VAR : {{ userCounts.total_var }}</span>
                </h5>
                <h5>
                  <span class="badge badge-primary text-xl"
                    >Resellers : {{ userCounts.total_resellers }}</span
                  >
                </h5>
                <h5>
                  <span class="badge badge-primary text-xl"
                    >Not Partners : {{ userCounts.not_partners }}</span
                  >
                </h5>
              </div>
              <div class="col-md-3 col-sm-12">
                <h5>
                  <span class="badge badge-warning text-xl"
                    >Activated Partners : {{ userCounts.activated_partners }}</span
                  >
                </h5>
                <h5>
                  <span class="badge badge-warning text-xl"
                    >Inactive Partners : {{ userCounts.inactive_partners }}</span
                  >
                </h5>
              </div>

              <div class="col-md-3 col-sm-12">
                <h5>
                  <span class="badge badge-success text-xl"
                    >Signed Agreements : {{ userCounts.signed_agreements }}
                  </span>
                </h5>
                <h5>
                  <span class="badge badge-success text-xl"
                    >Unsigned Agreements : {{ userCounts.unsigned_agreements }}
                  </span>
                </h5>
              </div>
            </div>
            <hr/>
            <div class="col-md-8 col-sm-12 ">
              <b-input-group class="mt-2 mb-3 shadow-sm">
                <b-form-input v-model="filter" placeholder="Search Partner"></b-form-input>

                <template #append>
                  <button type="button" class="btn btn-light">
                    <b-icon icon="search" aria-hidden="true"></b-icon>
                  </button>
                </template>
              </b-input-group>
            </div>
            <b-table
              :filter="filter"
              no-provider-paging
              :per-page="meta.perPage"
              primary-key="SN"
              :current-page="meta.currentPage"
              hover
              :fields="fields"
              :items="allUsers"
              class="text-sm"
            >
              <template #cell(name)="data">
                <b class="text-primary">{{ data.item.fname }} {{ data.item.lname }}</b>
              </template>

              <template #cell(doc_signed)="data">
                <img
                  :src="require('../assets/check.svg')"
                  v-if="data.item.doc_signed"
                  class="w-5"
                  alt=""
                />
                <img :src="require('../assets/cancel.svg')" v-else class="w-5" alt="" />
              </template>

              <template #cell(partner_type)="data">
                <span v-if="!data.item.is_an_affiliate">{{ data.item.partner_type }} </span>

                <span v-else>|affiliate</span>
              </template>

              <template #cell(date_signed)="data">
                <div v-if="!data.item.doc_signed">N/A</div>
                <div>{{ data.item.date_signed | moment("dddd, MMMM Do YYYY") }}</div>
              </template>
              <template #cell(doc_link)="data">

                <p  v-if="!data.item.doc_link">No file</p>
                <a   v-if="data.item.doc_link"  :href="data.item.doc_link" target="_blank">Download</a>
              </template>
              <template #cell(activate)="data" class="text-xs">
                <button
                  class="btn btn-dark btn-xs text-xs"
                  v-if="!data.item.activated"
                  @click="switchPartnerStatus(data.item.id, true)"
                >
                  Activate
                </button>
                <template v-else>
                  <span class="badge badge-success">Activated</span>
                  <div class="p-0 m-0">
                    <button
                      @click="switchPartnerStatus(data.item.id, false)"
                      class="btn btn-link btn-sm btn-xs p-0 m-0"
                    >
                      Deactivate
                    </button>
                  </div>
                </template>
              </template>
              <template #cell(deleterow)="data" class="text-xs">
                <button class="btn btn-danger btn-xs text-xs" @click="deleteRow(data.item.email)">
                  Delete
                </button>
              </template>
            </b-table>
            <div class="row justify-content-end">
              <b-col sm="12" md="6" class="my-1 ">
                <b-pagination
                  v-model="meta.currentPage"
                  :total-rows="meta.totalRows"
                  :per-page="meta.perPage"
                  align="right"
                  size="sm"
                  pills
                  limit="10"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Admin",
  data() {
    return {
      userCounts: {
        total_partners: 0,
        signed_agreements: 0,
        unsigned_agreements: 0,
        activated_partners: 0,
        inactive_partners: 0,
        total_var: 0,
        total_resellers: 0,
        not_partners: 0
      },
      filter: null,
      fields: [
        {
          key: "index",
          label: "SN",
          sortable: true
        },
        {
          key: "name",
          sortable: true
        },
        {
          key: "phone_no",
          label: "Phone No",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "partner_type",
          label: "Partner Type ",
          sortable: false
        },
        {
          key: "doc_link",
          label: "File",
          sortable: true
        },
        {
          key: "doc_signed",
          label: "Signed",
          sortable: true
        },
        {
          key: "date_signed",
          label: "Date Signed",
          sortable: true
        },
        {
          key: "activate",
          label: "Activate",
          sortable: true
        },
        {
          key: "deleterow",
          label: "Delete",
          sortable: false
        }
      ],
      allUsers: [],
      meta: {
        currentPage: 1,
        totalRows: 20,
        perPage: 20
      }
    };
  },
  methods: {
    getAllUsers() {
      this.showLoader();
      this.$_getAllUsers().then(response => {
        this.allUsers = response.data.body;
        this.userCounts = response.data.userCounts;
        this.meta = response.data.meta;
        this.hideLoader();
      });
    },
    switchPartnerStatus(id, status) {
      let payload = {
        id,
        status
      };
      this.$_switchPartnerStatus(payload).then(response => {
        this.getAllUsers();
      });
    },
    deleteRow(email) {
      let deleteConfirm = confirm("Do you want to delete this partner?");
      if (deleteConfirm) {
        let payload = { email };

        this.$_deletePartner(payload).then(response => {
          this.showLoader();
          this.$_getAllUsers()
            .then(response => {
              this.allUsers = response.data.body;
              this.meta = response.data.meta;
              this.hideLoader();
            })
            .bind(this);
        });
      } else {
      }
    }
  },
  components: {},
  mounted() {
    if (this.$accountUser.auth_role !== "mgt") {
      this.$logout();
      this.$router.push({ name: "Login" });
    }
    this.getAllUsers();
  }

};
</script>
<style>
.btn-xs {
  font-size: 11px !important;
}
</style>
