import Vue from "vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
const mixin={
    data(){
        return {
            loaderComponent:null
        }
    },
    methods:{
        showLoader() {
            this.loaderComponent = Loading.show({})
        },
        hideLoader() {
            this.loaderComponent.hide()
        }
    }
}
export default mixin;
