/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import Axios from 'axios';
// const ApiUrl = "http://52.249.192.165:3333/";
// let aceUser = JSON.parse(localStorage.getItem("aceUser"))
import industries from '../industries.json';

const ApiUrl = 'https://partner-api.scelloo.com/';
// const ApiUrl = "http://localhost:3333/";;

const $AxiosInstanceWithAUTH = Axios.create({
  baseURL: ApiUrl,
  timeout: 40000,
  headers: {
    'Content-Type': 'application/json',
  },
});
const $AxiosInstanceNoAUTH = Axios.create({
  baseURL: ApiUrl,
  timeout: 40000,
});
$AxiosInstanceWithAUTH.interceptors.request.use((config) => {
  config.headers.common.Authorization = (JSON.parse(localStorage.getItem('token')));
  return config;
}, (error) =>
// Do something with request error
  // eslint-disable-next-line implicit-arrow-linebreak
  Promise.reject(error));

const apiFactory = {
  $_getIp(_payload) {
    const url = 'https://api.ipify.org?format=json';
    return $AxiosInstanceNoAUTH.get(url);
  },
  $_createUser(payload) {
    const url = 'signup';
    return $AxiosInstanceNoAUTH.post(url, payload);
  },
  $_login(payload) {
    const url = 'login';
    return $AxiosInstanceNoAUTH.post(url, payload);
  },
  $_createServer(payload) {
    const url = 'server/create';
    return $AxiosInstanceNoAUTH.post(url, payload);
  },
  $_getAllUsers() {
    const url = 'users';
    return $AxiosInstanceNoAUTH.get(url);
  },
  $_switchPartnerStatus(payload) {
    const url = '/partner/switch-status';
    return $AxiosInstanceNoAUTH.post(url, payload);
  },
  $_signAgreement(payload) {
    const url = '/partner/sign-agreement';
    return $AxiosInstanceNoAUTH.post(url, payload);
  },
  $_getIndustries() {
    return industries;
  },
  $_apply(payload) {
    const url = '/apply';
    return $AxiosInstanceNoAUTH.post(url, payload);
  },
  $_deletePartner(payload) {
    console.log(payload);
    const url = '/partner/delete';
    return $AxiosInstanceNoAUTH.post(url, payload);
  },
};

export default apiFactory;
