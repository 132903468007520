import Vue from 'vue';
import { BootstrapVue,IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-moment'));
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/main.scss';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.config.productionTip = false;
import apiFactory from "./utils/apiFactory"
import utilities from "./utils/mixin"
import router from './router';
import store from './store';
import App from './App.vue';
const mixins={
  data() {
    return {
      loaderComponent: null
    }
  },
  methods: {
    ...apiFactory,
    async $logout() {
      this.showLoader()
      localStorage.removeItem("accountUser")
      localStorage.removeItem("token")
      await this.$router.push({path: "/"})
      this.hideLoader()
    },
    randomString() {
      // return randomstring.generate();
    },
    showLoader() {
      this.loaderComponent = this.$loading.show({})
    },
    hideLoader() {
      this.loaderComponent.hide()
    }
  },
  computed:{
    $accountUser() {
      //set state
      let accountUser = localStorage.getItem("accountUser")
      console.log(accountUser)
      if (accountUser === undefined) {
        localStorage.removeItem("accountUser",)
      }
      if (accountUser !== null) {
        return JSON.parse(accountUser);
      }
    },
  },
}
Vue.mixin(mixins)
new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount('#app');
